
import { currency } from "@/lib/a-format";
import Vue, { PropType } from "vue";
import aTextField from "./a-text-field.vue";

export default Vue.extend({
  name: "ATextFieldCurrency",

  components: { aTextField },

  props: {
    backgroundColor: {
      type: String as PropType<string>,
      default: "inputbg",
    },

    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    label: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },

    prefix: {
      type: String as PropType<string>,
      default: "R$",
    },

    raw: {
      type: String as PropType<string>,
      default: "",
    },

    rules: {
      type: Array as PropType<Array<(value: string) => string | boolean>>,
      default: () => [],
    },

    value: {
      type: String as PropType<string>,
      default: "",
    },
  },

  computed: {},

  watch: {
    value() {
      this.emit(this.value);
    },
  },

  methods: {
    focus() {
      console.log("a-text-field-currency focus");
      (this.$refs.input as HTMLElement).focus();
    },

    filter(event: KeyboardEvent) {
      if (event.key === "Enter") {
        this.$emit("keypress", event);
        return true;
      } else if (/^[0-9]+$/.test(event.key)) return true;
      else event.preventDefault();
    },

    emit(value: string) {
      const raw = value.replace(/[^\d]/g, "");
      this.$emit("update:raw", raw);

      if (raw.length > 2) {
        const rule = /^(\d+)(\d{2})$/;
        const format = "$1.$2";

        value = raw.replace(rule, format);
        value = currency(value, "BRL");
      } else value = raw;

      this.$emit("input", value);
    },

    evOnBlur() {
      if (this.value.length === 2)
        this.$emit("input", currency(this.value, "BRL"));
    },
  },
});
