
import AAutocomplete from "@/components/elements/a-autocomplete.vue";
import ABtn from "@/components/elements/a-btn.vue";
import aDialog from "@/components/elements/a-dialog.vue";
import { Rq } from "@/lib/amdt";
import Api from "@/lib/api";
import IImportApp from "@/lib/interfaces/import-app";
import IContaItem from "@/lib/interfaces/conta-item";
import Vue, { PropType } from "vue";
import ADate from "@/lib/a-date";
import { required } from "@/lib/validations";
import ADatePickerMultiple from "@/components/elements/a-date-picker-multiple.vue";

interface IImportData {
  regiaoOrigem: string;
  data: string;
  nregiao: string;
  idPessoa: string;
  nomePessoa: string;
  idPlanoConta: string;
  nomePlanoConta: string;
  valor: string;
  tipo: "1" | "2";
}

export default Vue.extend({
  name: "ImportContaItens",

  components: { aDialog, AAutocomplete, ABtn, ADatePickerMultiple },

  props: {
    idCentroCusto: {
      type: String as PropType<string>,
      required: true,
    },

    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  data() {
    return {
      loading: false,
      ret_id: 1,
      ret_msg: "",

      valid: true,

      visible: false,
      idAplicacao: "",

      min: new ADate().addDate(-1, -1, 0).dateString,
      max: new ADate().addDate(0, 0, 0).dateString,
      dates: [new ADate().addDate(0, 0, -1).dateString],
      closedDates: [] as string[],

      apps: new Array<IImportApp>(),
    };
  },

  computed: {
    noDateSelected(): boolean {
      return this.dates.length === 0;
    },
  },

  watch: {
    idCentroCusto() {
      this.getApps().then();
      this.getDatasFechamento().then();
    },
  },

  methods: {
    required,

    allowedDates(value: string) {
      return !this.closedDates.includes(value);
    },

    async getApps() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        if (!this.idCentroCusto) return;

        const rq = new Rq("aplicacao.read", {
          id_centro_custo: this.idCentroCusto,
          linked: "1",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.apps = rsp.getTable("aplicacao")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getDatasFechamento() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        if (!this.idCentroCusto) return;

        const rq = new Rq("contas.dataFechamento", {
          id_centro_custo: this.idCentroCusto,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        const closedDates: { dataImporte: string }[] =
          rsp.getTable("tblDataImporte")?.getRowsObject() ?? [];

        this.closedDates = closedDates.map((date) => date.dataImporte);

        this.dates = this.dates.filter(
          (date) => !this.closedDates.includes(date)
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async evOnSubmit() {
      try {
        this.loading = true;

        const rq = new Rq("api_importer.getInfo", {
          id_aplicacao: this.idAplicacao,
          id_centro_custo: this.idCentroCusto,
          data_movimentacao: this.dates.join(","),
        });

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        const results: IImportData[] =
          rsp.getTable("financeiro")?.getRowsObject() ?? [];

        const contaItems: IContaItem[] = results.map((item) => ({
          id: "default",
          conta_id: "",
          pessoa_id: item.idPessoa,
          plano_de_contas_id: item.idPlanoConta,
          descricao: `${item.data} - ${item.regiaoOrigem} - ${item.nregiao}: ${item.nomePlanoConta}`,
          data_vencimento: "",
          data_fechamento: ADate.today(true).dateTimeString,
          tipo: item.tipo,
          valor: item.valor,
          desativado: "0",
        }));

        this.$emit("imported", contaItems);
        this.visible = false;
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
