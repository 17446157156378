
import Api from "@/lib/api";
import Vue from "vue";
import { Rq, Table } from "@/lib/amdt";
import VForm from "@/lib/types/v-form";
import WorgError from "@/lib/worg-error";
import {
  required,
  maxLength,
  minLength,
  greaterThan,
  lessThan,
  before,
} from "@/lib/validations";
import vAlertMessage from "@/components/layout/v-alert-message.vue";
import AAutocomplete from "@/components/elements/a-autocomplete.vue";
import ABtn from "@/components/elements/a-btn.vue";
import ATextField from "@/components/elements/a-text-field.vue";
import ADate from "@/lib/a-date";
import ATextFieldCurrency from "@/components/elements/a-text-field-currency.vue";
import ADataTable from "@/components/elements/a-data-table.vue";
import ICostCenter from "@/lib/interfaces/cost-center";
import IAccountPlan from "@/lib/interfaces/account-plan";
import IConta from "@/lib/interfaces/conta";
import IContaItem from "@/lib/interfaces/conta-item";
import IPerson from "@/lib/interfaces/person";
import { currency } from "@/lib/a-format";
import { parseBRL } from "@/lib/util";
import VTextField from "@/lib/types/v-text-field";
import ADialogAutocomplete from "@/components/elements/a-dialog-autocomplete.vue";
import worgErrorObject from "@/lib/worg-error-object";
import ABtnDialogConfirm from "@/components/elements/a-btn-dialog-confirm.vue";
import ImportContaItens from "@/components/subviews/movement/import-conta-itens.vue";
import AccountPlansMaintenanceDialog from "@/components/subviews/account-plans/account-plans-maintenance-dialog.vue";

interface IEntry {
  key: string;
  id: "default" | string;
  conta_id: string;
  pessoa_id: string;
  plano_de_contas_id: string;
  descricao: string;
  data_vencimento: string;
  data_fechamento: string;
  credito: string;
  debito: string;
  desativado: "0" | "1";
}

export default Vue.extend({
  name: "Movement",

  components: {
    vAlertMessage,
    AAutocomplete,
    ABtn,
    ATextField,
    ATextFieldCurrency,
    ADataTable,
    ADialogAutocomplete,
    ABtnDialogConfirm,
    ImportContaItens,
    AccountPlansMaintenanceDialog,
  },

  data() {
    return {
      loading: false,
      valid: false,
      ret_id: 1,
      ret_msg: "",

      CREDITO: "1",
      DEBITO: "2",
      DEBITO_CREDITO: "3",

      costCenters: new Array<ICostCenter>(),
      accountPlans: new Array<IAccountPlan>(),
      people: new Array<IPerson>(),

      saldoAnterior: 0,

      dataConta: {
        id: "default",
        centro_custo_id: "",
        empresa_id: "",
        data_lancamento: new ADate().dateString,
        data_criacao: "",
        data_atualizacao: "",
        valor: "",
        desativado: "0",
      } as IConta,

      entries: new Array<IEntry>(),

      headers: [
        { text: "Ações", value: "actions" },
        { text: "Plano de Conta", value: "plano_de_contas_id" },
        { text: "Pessoa", value: "pessoa_id" },
        { text: "Descrição", value: "descricao" },
        { text: "Entrada", value: "credito" },
        { text: "Saída", value: "debito" },
      ],

      resultsHeaders: [
        {
          text: "Saldo anterior",
          value: "saldo_anterior",
          align: "right",
          sortable: false,
        },
        {
          text: "Total do dia entradas",
          value: "creditos_dia",
          align: "right",
          sortable: false,
        },
        {
          text: "Total do dia saídas",
          value: "debitos_dia",
          align: "right",
          sortable: false,
        },
        {
          text: "Total do dia",
          value: "saldo_dia",
          align: "right",
          sortable: false,
        },
        { text: "Saldo", value: "saldo", align: "right", sortable: false },
      ],
    };
  },

  computed: {
    form(): VForm {
      return this.$refs.form as VForm;
    },

    cEntries(): IEntry[] {
      return this.entries.filter((item) => item.desativado === "0");
    },

    cAccountPlans(): Array<Record<string, string>> {
      return this.accountPlans.map((accountPlan) => ({
        ...accountPlan,
        text: `${accountPlan.nivel} ${accountPlan.descricao}`,
      }));
    },

    cBtnAddEntryDisabled(): boolean {
      return (
        !this.dataConta.centro_custo_id ||
        !this.dataConta.data_lancamento ||
        this.loading
      );
    },

    cBtnImportDisabled(): boolean {
      return (
        !this.dataConta.centro_custo_id ||
        !this.dataConta.data_lancamento ||
        this.before("tomorrow")(this.dataConta.data_lancamento) !== true ||
        this.loading
      );
    },

    cBtnSaveDisabled(): boolean {
      if (this.cEntries.some((entry) => !entry.pessoa_id)) return true;

      return (
        !this.dataConta.centro_custo_id ||
        !this.dataConta.data_lancamento ||
        !this.valid ||
        this.loading ||
        this.contaItems.length === 0
      );
    },

    contaItems(): Array<IContaItem> {
      return this.entries
        .filter((entry) => Boolean(entry.plano_de_contas_id))
        .map((entry) => {
          const accountPlan = this.accountPlans.find(
            (ap) => ap.id === entry.plano_de_contas_id
          ) as IAccountPlan;

          let tipo;
          if (accountPlan.tipo === this.CREDITO) tipo = this.CREDITO;
          else if (accountPlan.tipo === this.DEBITO) tipo = this.DEBITO;
          else tipo = entry.credito.length > 0 ? this.CREDITO : this.DEBITO;

          const valor = tipo === this.CREDITO ? entry.credito : entry.debito;

          return {
            id: entry.id,
            conta_id: this.dataConta.id,
            pessoa_id: entry.pessoa_id,
            plano_de_contas_id: entry.plano_de_contas_id,
            descricao: entry.descricao,
            data_vencimento: entry.data_vencimento,
            data_fechamento: entry.data_fechamento,
            tipo,
            valor,
            desativado: entry.desativado,
          } as IContaItem;
        });
    },

    cResults(): Record<string, { value: string; color: string }>[] {
      const creditosDia = this.contaItems
        .filter(
          (contaItem) =>
            contaItem.tipo === this.CREDITO && contaItem.desativado === "0"
        )
        .reduce((acc, contaItem) => (acc += parseBRL(contaItem.valor)), 0);

      const debitosDia = this.contaItems
        .filter(
          (contaItem) =>
            contaItem.tipo === this.DEBITO && contaItem.desativado === "0"
        )
        .reduce((acc, contaItem) => (acc += parseBRL(contaItem.valor)), 0);

      const saldoDia = creditosDia - debitosDia;
      const saldo = this.saldoAnterior + saldoDia;

      return [
        {
          saldo_anterior: {
            value: `R$ ${this.saldoAnterior < 0 ? "-" : ""}${currency(
              this.saldoAnterior,
              "BRL"
            )}`,
            color: this.saldoAnterior < 0 ? "red--text" : "blue--text",
          },
          saldo_dia: {
            value: `R$ ${saldoDia < 0 ? "-" : ""}${currency(saldoDia, "BRL")}`,
            color: saldoDia < 0 ? "red--text" : "blue--text",
          },
          creditos_dia: {
            value: `R$ ${currency(creditosDia, "BRL")}`,
            color: creditosDia < 0 ? "red--text" : "blue--text",
          },
          debitos_dia: {
            value: `R$ ${currency(debitosDia, "BRL")}`,
            color: debitosDia < 0 ? "red--text" : "blue--text",
          },
          saldo: {
            value: `R$ ${saldo < 0 ? "-" : ""}${currency(saldo, "BRL")}`,
            color: saldo < 0 ? "red--text" : "blue--text",
          },
        },
      ];
    },
  },

  watch: {
    entries() {
      this.form.validate();
    },
  },

  mounted() {
    this.getAccountPlans().then();
    this.getCostCenters().then();
    this.getPeople().then();
  },

  methods: {
    minLength,
    maxLength,
    required,
    before,

    randomKey(): string {
      const random = new Uint32Array(1);
      window.crypto.getRandomValues(random);
      return random[0].toString();
    },

    creditoDebitoRules(
      item: IEntry,
      tipo: "1" | "2"
    ): ((value: string) => string | boolean)[] {
      const rules: ((value: string) => string | boolean)[] = [];

      const accountPlan = this.accountPlans.find(
        (accountPlan) => accountPlan.id === item.plano_de_contas_id
      );

      if (!accountPlan) return rules;

      if (accountPlan.tipo === tipo)
        rules.push(greaterThan(-1), lessThan(1000000000));
      else if (item.credito.length > 0 && tipo === this.CREDITO)
        rules.push(greaterThan(-1), lessThan(1000000000));
      else if (item.debito.length > 0 && tipo === this.DEBITO)
        rules.push(greaterThan(-1), lessThan(1000000000));

      return rules;
    },

    entradaSaidaDisabled(item: IEntry, tipo: "1" | "2"): boolean {
      const accountPlan = this.accountPlans.find(
        (accountPlan) => accountPlan.id === item.plano_de_contas_id
      );

      if (!accountPlan) return true;

      if (accountPlan.tipo === this.DEBITO_CREDITO) {
        if (tipo === this.DEBITO && item.credito.length > 0) return true;

        if (tipo === this.CREDITO && item.debito.length > 0) return true;

        return false;
      }

      return accountPlan.tipo !== tipo;
    },

    evOnPlanoContaInput(item: IEntry) {
      item.credito = "";
      item.debito = "";
    },

    addEntry() {
      const entry: IEntry = {
        key: this.randomKey(),
        id: "default",
        conta_id: "",
        pessoa_id: "",
        plano_de_contas_id: "",
        descricao: "",
        data_vencimento: new ADate().dateString,
        data_fechamento: new ADate().dateString,
        credito: "",
        debito: "",
        desativado: "0",
      };

      this.entries.push({ ...entry });

      setTimeout(() => {
        const input = this.$refs[`entry-${entry.key}`] as VTextField;
        input.focus();
      }, 100);
    },

    async removeEntry(item: IEntry) {
      if (item.id === "default") {
        const index = this.entries.findIndex((entry) => entry === item);
        if (index > -1) this.entries.splice(index, 1);
      } else {
        item.desativado = "1";
      }
    },

    async evOnSubmit() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        if (
          !this.valid ||
          this.before("tomorrow")(this.dataConta.data_lancamento) !== true
        )
          return;

        const procRun =
          this.dataConta.id === "default" ? "contas.create" : "contas.update";

        const rq = new Rq(procRun);

        //#region conta
        const tblContas = new Table("contas");
        const conta = { ...this.dataConta };
        tblContas.setColsFromObject(conta);
        tblContas.addRowsFromObject(conta);
        rq.addTable(tblContas);
        //#endregion

        //#region conta item
        const tblContaItems = new Table("conta_itens");
        const contaItem = { ...this.contaItems[0] };
        tblContaItems.setColsFromObject(contaItem);
        tblContaItems.addRowsFromObject(
          ...this.contaItems.map((contaItem) => ({
            ...contaItem,
            valor: contaItem.valor
              .replaceAll(/[^\d,]/g, "")
              .replaceAll(",", "."),
          }))
        );
        rq.addTable(tblContaItems);
        //#endregion

        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;

          if (WorgError.getErrorByCode(this.ret_id).name === "VldError")
            this.form.validate();

          this.loading = false;
          return;
        }

        this.ret_msg = `Movimentação ${
          procRun === "contas.create" ? "cadastrada" : "atualizada"
        } com sucesso`;

        this.getConta().then();
      } catch (error) {
        console.error(error);
      }
    },

    async getConta() {
      try {
        if (this.cBtnAddEntryDisabled && !this.loading) return;

        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("contas.get", {
          dataLancamento: this.dataConta.data_lancamento,
          centroCustoId: this.dataConta.centro_custo_id,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.loading = false;

          if (rsp.ret_id === worgErrorObject.ErrNoRows.code) {
            this.dataConta = {
              ...this.dataConta,
              id: "default",
              data_criacao: "default",
              data_atualizacao: "",
              valor: "0",
              desativado: "0",
            };

            this.entries = [];
            return;
          }

          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.saldoAnterior = Number(rsp.params?.saldoAnterior ?? "0");

        const tblContas = rsp.getTable("contas")?.getRowsObject() ?? [];
        if (tblContas.length > 0) {
          this.dataConta = { ...tblContas[0] };
        } else {
          this.dataConta = {
            ...this.dataConta,
            id: "default",
            data_criacao: "default",
            data_atualizacao: "",
            valor: "0",
            desativado: "0",
          };

          this.entries = [];
        }

        this.getContaItens().then();
      } catch (error) {
        console.error(error);
      }
    },

    async getContaItens() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        if (!this.dataConta.id) return;

        const rq = new Rq("conta_itens.read", {
          contaId: this.dataConta.id,
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        const contaItens: IContaItem[] =
          rsp.getTable("conta_itens")?.getRowsObject() ?? [];

        this.entries = contaItens.map((contaItem) =>
          this.contaItemToEntry(contaItem)
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async evOnImported(contaItens: IContaItem[]) {
      for (const contaItem of contaItens)
        this.entries.push(this.contaItemToEntry(contaItem));
    },

    contaItemToEntry(contaItem: IContaItem) {
      return {
        key: this.randomKey(),
        id: contaItem.id,
        conta_id: contaItem.conta_id,
        pessoa_id: contaItem.pessoa_id,
        plano_de_contas_id: contaItem.plano_de_contas_id,
        descricao: contaItem.descricao,
        data_vencimento: contaItem.data_vencimento
          ? contaItem.data_vencimento
          : this.dataConta.data_lancamento,
        data_fechamento: contaItem.data_fechamento
          ? contaItem.data_fechamento
          : this.dataConta.data_lancamento,
        credito:
          contaItem.tipo === this.CREDITO
            ? currency(contaItem.valor, "BRL")
            : "",
        debito:
          contaItem.tipo === this.DEBITO
            ? currency(contaItem.valor, "BRL")
            : "",
        desativado: contaItem.desativado,
      };
    },

    async getPeople() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("pessoas.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.people = rsp.getTable("pessoas")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getAccountPlans() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("plano_de_conta.read", {
          sortBy: "nivel",
          itemsPerPage: "-1",
        });
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.accountPlans = rsp.getTable("plano_contas")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },

    async getCostCenters() {
      try {
        this.loading = true;
        this.ret_id = 1;
        this.ret_msg = "";

        const rq = new Rq("centro_de_custo.read");
        const rsp = await Api.request(rq);

        if (rsp.ret_id < 1) {
          this.ret_id = rsp.ret_id;
          this.ret_msg = rsp.ret_msg;
          return;
        }

        this.costCenters = rsp.getTable("centro_custo")?.getRowsObject() ?? [];
      } catch (error) {
        console.error(error);
      } finally {
        this.loading = false;
      }
    },
  },
});
