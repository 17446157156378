
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "ADatePickerMultiple",

  components: {},

  props: {
    allowedDates: {
      type: Function as PropType<(value: string) => boolean>,
      default: undefined,
    },

    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    events: {
      type: Array as PropType<string[]>,
      default: () => [],
    },

    min: {
      type: String as PropType<string>,
      default: undefined,
    },

    max: {
      type: String as PropType<string>,
      default: undefined,
    },

    value: {
      type: Array as PropType<string[]>,
      required: true,
    },
  },

  data() {
    return {};
  },

  methods: {},
});
