
import ABtn from "@/components/elements/a-btn.vue";
import aDialog from "@/components/elements/a-dialog.vue";
import Vue from "vue";
import AccountPlansMaintenance from "./account-plans-maintenance.vue";

export default Vue.extend({
  name: "AccountPlansMaintenanceDialog",

  components: { aDialog, AccountPlansMaintenance, ABtn },

  data() {
    return {
      visible: false,
    };
  },

  computed: {
    cWidth(): string {
      return this.isMobile ? "100%" : "35%";
    },

    isMobile(): boolean {
      return this.$vuetify.breakpoint.mobile;
    },
  },
});
