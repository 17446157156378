
import Vue, { PropType } from "vue";
import AAutocomplete from "./a-autocomplete.vue";
import ABtn from "./a-btn.vue";
import aDialog from "./a-dialog.vue";

export default Vue.extend({
  name: "ADialogAutocomplete",

  components: { aDialog, ABtn, AAutocomplete },

  props: {
    disabled: {
      type: Boolean as PropType<boolean>,
      default: false,
    },

    items: {
      type: Array as PropType<Array<Record<string, string>>>,
      required: true,
    },

    itemText: {
      type: String as PropType<string>,
      default: "",
    },

    itemValue: {
      type: String as PropType<string>,
      default: "",
    },

    value: {
      type: String as PropType<string>,
      required: true,
    },
  },

  data() {
    return {
      visible: false,
    };
  },

  computed: {
    append(): boolean {
      return Boolean(this.$slots["append"]);
    },

    cItemText(): string {
      if (this.itemText || this.items.length === 0) return this.itemText;

      const item = this.items[0] as Record<string, unknown>;
      const keys = Object.keys(item);

      const options = [
        "text",
        "nome",
        "descricao",
        "name",
        "description",
        "id",
      ];
      return options.find((op) => keys.includes(op)) ?? keys[0];
    },

    cItemValue(): string {
      if (this.itemValue || this.items.length === 0) return this.itemValue;

      const item = this.items[0] as Record<string, unknown>;
      const keys = Object.keys(item);

      const options = ["id", "value"];
      return options.find((op) => keys.includes(op)) ?? keys[0];
    },

    cSelectedItem(): Record<string, string> | undefined {
      return this.items.find((item) => item[this.cItemValue] === this.value);
    },

    cText(): string {
      if (!this.cSelectedItem) return "Selecione";

      return this.cSelectedItem[this.cItemText];
    },

    cWidth(): string {
      return this.isMobile ? "100%" : "35%";
    },

    isMobile(): boolean {
      return this.$vuetify.breakpoint.mobile;
    },

    autocomplete(): HTMLElement {
      return this.$refs.autocomplete as HTMLElement;
    },
  },

  watch: {
    visible() {
      if (this.visible)
        setTimeout(() => {
          this.autocomplete.focus();
        }, 100);
    },
  },

  methods: {
    click(): void {
      this.visible = true;
    },

    evOnInput(value: string) {
      this.visible = false;
      this.$emit("input", value);
    },
  },
});
